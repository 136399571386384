<template>
    <BT-Blade-Items
        bladeName="stock-adjustments"
        :bladesData="bladesData"
        :canCreate="false"
        :canEdit="false"
        :canExportCSV="false"
        :canSearch="false"
        :canSelect="false"
        :getParams="bladeData => { return { includeDetails: false, locationID: bladeData.data.locationID, productID: bladeData.data.productID, batchID: bladeData.data.batchID }}"
        :headers="[
            { text: 'User', value: 'userID', display: true, title: 1 },
            { text: 'Adjusted On', value: 'createdOn', textFilter: 'toShortDateAndTime', subtitle: 1 }]"
        navigation="stock-adjustments"
        showList
        title="Stock Adjustments"
        useServerFilters
        useServerPagination>
        <template v-slot:userID="{ item }">
            <BT-Entity
                alternateText="Someone"
                class="mr-1"
                navigation="users"
                inline
                itemText="userName"
                :itemValue="item.userID" />
                <span v-if="item.adjustmentType == 'Waste'"> wasted </span>
                <span v-else-if="item.adjustmentType == 'Add'"> added </span>
                <span v-else-if="item.adjustmentType == 'Remove'"> removed </span>
                <span v-else-if="item.adjustmentType == 'Hold'"> held back </span>
                <span v-else-if="item.adjustmentType == 'OnHold'"> held all </span>
                <span v-else-if="item.adjustmentType == 'Use'"> used </span>
                {{ item.quantity | toDisplayNumber }} unit{{ item.quantity != 1 ? 's' : '' }}
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Stock-Adjustments-Blade',
    props: {
        bladesData: null
    }
}

//:itemProperties="['ID', 'CreatedOn', 'LocationID', 'ProductID', 'BatchID', 'AdjustmentType', 'Quantity', 'UserID']"
</script>